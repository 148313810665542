import React from 'react';
import { Input, message, Spin } from 'antd';
import './index.less';
const IconFont = Loader.loadBaseComponent('IconFont');
class UploadInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      uploadImgUrl: props.searchData.uploadImgUrl,
      keywords: props.searchData.keywords
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.searchData.uploadImgUrl !== this.props.searchData.uploadImgUrl){
      this.setState({uploadImgUrl:nextProps.searchData.uploadImgUrl})
    }
  }

  /**
   * @desc 拖拽
   */
  onDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    let df = e.dataTransfer.items[0];
    var file = df.getAsFile();
    this.upDataImg(file);
  };

  /**
   * @desc 点击图标进行图片上传
   */
  fileInputChange = e => {
    let file = e.target.files[0];
    this.upDataImg(file);
  };

  /**
   * @desc 上传到后台获取url
   */
  upDataImg = async file => {
    if (file.size > 5 * 1024 * 1024) {
      message.warn('图片大小不能超过5M!');
      return 
    }
    if (!file.size) {
      message.warn('图片文件错误!');
      return 
    }
    let newFile = file;
    if (file.type !== 'image/svg+xml') {
      newFile = await Utils.imageCompress(file);
    }
    const formData = new FormData();
    formData.append('file', newFile);
    this.setState({
      loading: true
    });
    Service.vehicle.uploadImg(formData).then(result => {
      this.getImgFeature(result.data.url);
    })
    .catch(e => {
      message.error('图片上传失败');
      this.setState({
        loading: false
      });
    });
  };

  /**
   * @desc 提取图片特征
   */
  getImgFeature = (imgUrl) => {
    const { onTypeChange } = this.props;
    let options = { score: 0.6, url: imgUrl };
    Service.vehicle.getFeature(options).then(res => {
      const vehicles = res.data.vehicles
      if(vehicles && vehicles.length > 0){
        let plateNo = vehicles[0].plateNo
        let feature = vehicles[0].feature
        // 处理数据-向上传递，如果有车牌，使用车牌搜索，没有的话使用特征值搜索
        if(plateNo){
          onTypeChange && onTypeChange({
            plateNo,
            feature:undefined,
            uploadImgUrl: imgUrl,
          })
        }else{
          onTypeChange && onTypeChange({
            feature,
            plateNo: undefined,
            uploadImgUrl: imgUrl,
          })
        }
        this.setState({
          uploadImgUrl: imgUrl,
          loading: false
        })
      }else{
        message.warn('提取图片特征失败,请重新上传');
        this.setState({
          loading: false
        })
      }
    })
    .catch(() => {
      message.error('提取图片特征失败,请重新上传');
      this.setState({
        loading: false
      });
    })
  };


  /**
   * @desc 默认行为
   */
  onDragOver = e => {
    e.preventDefault();
  };


  /**
   * @desc 处理关键字搜索的问题
   */
  onChange = e => {
    let keywords = e.target.value
    this.setState({
      keywords
    })
  };

  /**
   * @desc 点击删除图片
   */
  deleteImg = () => {
    const { onTypeChange } = this.props
    this.setState({
      uploadImgUrl: '',
      keywords: '',
      loading: false
    })
    onTypeChange && onTypeChange({
      plateNo: undefined,
      feature:undefined,
      keywords: undefined,
      uploadImgUrl: undefined,
    })
  };

  /**
   * @desc 点击搜索按钮
   */
  search = () => {
    let { keywords } = this.state;
    this.props.onTypeChange && this.props.onTypeChange({
      keywords
    });
  }

  render() {
    let { placeholder = '请输入关键字搜索' } = this.props;
    let { loading, uploadImgUrl, keywords } = this.state;
    return (
      <div className="upload-input-view">
        <div className="upload-input-box">
          <Input
            onDrop={this.onDrop}
            onPressEnter={this.search}
            onDragLeave={this.onDragLeave}
            onDragOver={this.onDragOver}
            onChange={this.onChange}
            value={keywords}
            style={uploadImgUrl || loading ? { paddingLeft: '33px' } : {}}
            className="upload-input"
            placeholder={placeholder}
          />
          <div className="delete-pic-input">
            {(uploadImgUrl || keywords) && <IconFont onClick={this.deleteImg} type={'icon-S_Edit_Clear'} theme="outlined" style={{ fontSize: '16px' }} />}
          </div>
          <div className="camera-btn">
            <label>
              <Input type="file" accept="image/*" onChange={this.fileInputChange} style={{ visibility: 'hidden', position: 'fixed' }} />
              {!loading && !uploadImgUrl && !keywords && (
                <IconFont
                  type={'icon-S_Edit_ImgSearch'}
                  theme="outlined"
                  style={{
                    fontSize: '18px',
                    color: '#8899BB',
                    marginTop: '0px',
                    marginLeft: '3px'
                  }}
                />
              )}
            </label>
          </div>
          <div className="search-btn" onClick={this.search}>
            <IconFont type={'icon-S_Edit_Search'} theme="outlined" />
          </div>
          {uploadImgUrl ? (
            <div className="img-view">
              <div className="img-box">
                <img src={uploadImgUrl} />
              </div>
            </div>
          ) : (
            <React.Fragment>
              {loading && (
                <div className="img-view" style={{ marginLeft: 3, marginTop: 2 }}>
                  <div className="img-box">
                    <Spin size="small" spinning={loading} />
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default UploadInput;
