import React from "react";
import Card from "../cardVehicle";
import { CVehicle } from "../../context";

const InfiniteScrollLayout = Loader.loadBaseComponent("ReactVirtualized", 'InfiniteScrollLayout');
const NoData = Loader.loadBaseComponent("NoData");
const Loading = Loader.Loading

@CVehicle
class Vehicles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLoadMore:true
    };
    this.infinitRef = React.createRef();
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.vehicles !== this.props.vehicles){
      this.infinitRef && this.infinitRef.current && this.infinitRef.current.forceUpdateGrid();
    }
  }

  loadMore = () => {
    let { searchVehicleData, setSearchVehicleData, total } = this.props;
    this.setState({
      hasLoadMore:false
    }, () => {
      if (searchVehicleData.offset > total - searchVehicleData.limit) {
        return;
      }
      searchVehicleData.offset += searchVehicleData.limit;
      setSearchVehicleData && setSearchVehicleData(searchVehicleData).then(length => {
        if(length === searchVehicleData.limit){
          this.setState({ hasLoadMore:true })
        }
      })
    })
   };
  render() {
    let { vehicles,infinitKey,jumpDetail,isRigested,rightLoading} = this.props;
    let { hasLoadMore } = this.state
    if(rightLoading){
      return <Loading />
    }
    if(!vehicles.length){
      return <NoData />
    }
    return (
      <InfiniteScrollLayout 
          className="right-vehicle-list"
          itemWidth={340}
          itemHeight={200}
          key={infinitKey}
          pdWidth={16}
          data={vehicles}
          ref={this.infinitRef}
          hasBackTop={true}
          loadMore={this.loadMore}
          hasLoadMore={hasLoadMore}
          renderItem={item => {
            const { id, vehiclePlateNum, deviceName, ownerName,vehicleUrl,appearCounts,recentTime } = item;
            return (
              <Card
                id={id}
                jumpDetail={jumpDetail}
                isRigested={isRigested}
                vehiclePlateNum={vehiclePlateNum}
                deviceName={deviceName}
                ownerName={ownerName}
                vehicleUrl={vehicleUrl}
                appearCounts={appearCounts}
                recentTime={recentTime}
                key={id}
              />
            );
          }}
      >
        
      </InfiniteScrollLayout>
    );
  }
}
export default Vehicles;
