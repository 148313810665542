import React from "react";
import { Provider } from "./context";
import { withRouter } from "react-router-dom";
import CommunityVehicleView from "./view";
import "./index.less";

@withRouter
@Decorator.businessProvider("tab")
class CommunityVehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      villages: [],
      vehicles: [],
      leftLoading: true,
      rightLoading: true,
      activeVillage: null,
      searchVillageName: "",
      total: 0,
      searchVehicleData: {
        keywords: "",
        sortType: 0,
        startTime: undefined,
        endTime: undefined,
        feature: undefined,
        limit: 200,
        offset: 0,
        villageIds: []
      }
    };
    this.isRigested =
      props.location.pathname.split("Vehicle")[1] === "Temporary"
        ? false
        : true;
    this.timer = null;
    this.first = false;
  }

  componentDidMount() {
    let { history } = this.props;
    const { id } = Utils.queryFormat(history.location.search);
    if (!id) {
      this.initVillages();
    } else {
      window.LM_DB.get("parameter", id).then(data =>
        this.setState({ searchVehicleData: data.searchData }, () =>
          this.initVillages()
        )
      );
    }
  }

  initVillages = () => {
    let { searchVillageName } = this.state;
    this.timer && clearTimeout(this.timer);
    this.timer = setTimeout(
      () =>
        Utils.catchPromise(
          Service.community.statisticsList({
            limit: 1000,
            offset: 0,
            keywords: searchVillageName
          })
        ).then(res =>
          this.setState({ villages: res.list, leftLoading: false }, () => {
            if(res.list.length > 0){
              this.setSearchVehicleData({
                villageIds: res.list.map(v => v.id)
              });
            }else{
              this.setState({vehicles:[],rightLoading: false})
            }
          })
        ),
      500
    );
  };

  // 更新地址栏url
  updateSearchDB = () => {
    const { tab } = this.props;
    const { searchVehicleData } = this.state;
    let id = Utils.uuid();
    window.LM_DB.add("parameter", {
      id,
      searchData: searchVehicleData
    }).then(() => {
      tab.goPage({
        moduleName: this.isRigested ? "communityVehicle" : 'communityVehicleTemporary',
        data: { id },
        action: "replace",
        isUpdate: true
      });
    });
  };

  initVehicles = () => {
    let { searchVehicleData } = this.state;
    Utils.catchPromise(
      Service.community[
        this.isRigested ? "queryRegisteredVehicle" : "queryTempVehicle"
      ]({ ...searchVehicleData, timeType: undefined })
    )
      .then(res => {
        this.first && this.updateSearchDB();
        this.first = true;
        this.setState({
          vehicles: res.list,
          total: res.total,
          rightLoading: false
        });
      })
      .catch(err => this.setState({ rightLoading: false }));
  };

  setSearchVehicleData = option => {
    return new Promise(() => {
      let { searchVehicleData } = this.state;
      this.setState(
        { searchVehicleData: { ...searchVehicleData, ...option } },
        () => this.initVehicles()
      );
    });
  };

  setSearchVillageData = words => {
    this.setState({ searchVillageName: words }, () => this.initVillages());
  };

  checkVillage = id => {
    this.setCheckbox(id);
    this.setSearchVehicleData({ villageIds: [id] ,offset:0});
  };

  setCheckbox = any => {
    this.setState({ activeVillage: any,searchVillageName:'' }, () => !any && this.initVillages());
  };

  jumpDetail = vehiclePlateNum => {
    const { tab } = this.props;
    const id = Utils.uuid();
    tab.openDetailPage({
      moduleName: 'vehicleFile',
      value: id,
      data: { id },
      beforeAction: async () => {
        await window.LM_DB.add('parameter', {
          id,
          vehiclePlateNum,
          isRigested: this.isRigested
        })
      }
    });
  };

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          jumpDetail: this.jumpDetail,
          isRigested: this.isRigested,
          checkVillage: this.checkVillage,
          setCheckbox: this.setCheckbox,
          setSearchVillageData: this.setSearchVillageData,
          setSearchVehicleData: this.setSearchVehicleData
        }}
      >
        <CommunityVehicleView />
      </Provider>
    );
  }
}
export default CommunityVehicle;
