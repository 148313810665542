import React from "react";
import { Checkbox } from "antd";
import { CVehicle } from "./context";
import Villages from "./components/leftList";
import Vehicles from "./components/rightList";
import SearchVehicle from "./components/searchVehicle";
import "./index.less";

const Wrapper = Loader.loadBaseComponent("TwoColumnLayout");
const InputAfter = Loader.loadBaseComponent("InputAfter");

@CVehicle
class CommunityVehicleView extends React.Component {
  render() {
    let {
      activeVillage,
      setCheckbox,
      searchVillageName,
      setSearchVillageData,
      searchVehicleData,
      isRigested,
      total
    } = this.props;
    return (
      <Wrapper
        className="community-vehicle"
        title={isRigested ? "小区车辆管理" : '临时车辆管理'}
        extra={
          <div>
            <span className="vehicle-total">
              共显示：
              <span className="number">
                {total ? Utils.thousand(total) : 0}
              </span>辆车
            </span>
          </div>
        }
        titleExtra={
          <div className="community-checkbox">
            <span className="checkbox">
              <Checkbox
                defaultChecked={true}
                checked={!activeVillage}
                onChange={() => setCheckbox(null)}
              />
            </span>
            全部显示
          </div>
        }
        renderLeft={
          <>
            <div className="community-input">
              <InputAfter
                placeholder="请输入小区名称搜索"
                size={"lg"}
                style={{ color: "rgba(0,0,0,.25)" }}
                onChange={e => setSearchVillageData(e.target.value)}
                onCancel={e => setCheckbox(null)}
                value={searchVillageName}
              />
            </div>
            <div className="community-exp">
              <Villages />
            </div>
          </>
        }
      >
        <SearchVehicle searchVehicleData={searchVehicleData} setSearchVillageData={setSearchVillageData} />
        <Vehicles />
      </Wrapper>
    );
  }
}
export default CommunityVehicleView;
