import React from "react";
import "./index.less";

const ImageView = Loader.loadBaseComponent("ImageView");
const IconFont = Loader.loadBaseComponent("IconFont");

export default ({ villageName, address, pictureUrl, checkVillage, id, activeVillage }) => {
  return (
    <div
      className={activeVillage && activeVillage === id ? "active village-card" : "village-card"}
      onClick={() => checkVillage(id)}
    >
      <div className="img-box">
        {pictureUrl ? (
          <ImageView hasErrorImageStyle={false} src={pictureUrl} />
        ) : (
          <IconFont style={{ fontSize: "80px", color: "#D8DCE3" }} type={"icon-M_AID_Community"} theme="outlined" />
        )}
      </div>
      <div className="info">
        <p className="title">{villageName}</p>
        <p>{address}</p>
      </div>
    </div>
  );
};
