import React from "react";
import { Divider } from "antd";
import "./index.less";

const ImageView = Loader.loadBaseComponent("ImageView");
const IconFont = Loader.loadBaseComponent("IconFont");

export default ({
  vehiclePlateNum,
  deviceName,
  ownerName,
  vehicleUrl,
  jumpDetail,
  appearCounts,
  recentTime,
  isRigested,
  id
}) => {
  return (
    <div className="community-vehicle-card" onClick={() => jumpDetail(vehiclePlateNum)}>
      <div className="img-box">
        <ImageView src={vehicleUrl} />
      </div>
      <div className="info">
        <p className="title">
          <IconFont type="icon-S_Bar_Brand" />
          {vehiclePlateNum || "暂无"}
        </p>
        {isRigested && (
          <p>
            <IconFont type="icon-S_AID_Avatar" />
            {ownerName || "暂无"}
          </p>
        )}
        <Divider style={{ backgroundColor: "var(--bg)", margin: "4px 0" }} />
        <p className="title-sub">最近出现：</p>
        <p>
          <IconFont type="icon-S_Tree_Add" />
          {((deviceName && Utils.getSubStr(deviceName))) || "暂无"}
        </p>
        <p>
          <IconFont type="icon-S_Edit_ClockStart" />
          {parseInt(recentTime) === 0 ?"暂无" : Utils.formatTimeStamp(recentTime)}
        </p>
        <Divider style={{ backgroundColor: "var(--bg)", margin: "4px 0" }} />
        <p className="title-sub">3天内出现：</p>
        <p>
          <IconFont type="icon-S_View_SelectChoosed" />
          {appearCounts || 0}
          <span className="num" />次
        </p>
      </div>
    </div>
  );
};
