import React from "react";
import { Select } from "antd";
import { CVehicle } from "../../context";
import './index.less'
import ImageAndWords from '../VehicleUpload/index.js'
const IconFont = Loader.loadBaseComponent("IconFont");
const TimeRadio = Loader.loadBusinessComponent(
  "BaseLibComponents",
  "AlarmTimeRadio"
);
// const ImageAndWords = Loader.loadBusinessComponent("CommunityImageInput");
const Option = Select.Option;

class TimeTypeSelect extends React.Component {
  constructor(props) {
    super(props);
  }
  // 时间筛选
  handleTimeSort = value => {
    this.props.onTypeChange({ offset: 0, sortType: value });
  };
  render() {
    let { searchData } = this.props;
    return (
      <div className="time-type">
        <Select
          dropdownClassName="time-type-downwrap"
          className="time-type-select"
          style={{ width: 150 }}
          value={searchData.sortType}
          onChange={this.handleTimeSort}
          defaultValue={0}
          size="small"
          suffixIcon={<IconFont type="icon-S_Arrow_BigDown" />}
        >
          <Option value={0}>按抓拍时间排序</Option>
          <Option value={1}>按抓拍次数排序</Option>
        </Select>
      </div>
    );
  }
}

@CVehicle
class SearchVehicle extends React.Component {
  render() {
    let { searchVehicleData, setSearchVehicleData, isRigested } = this.props;
    return (
      <div className="search-vehicle">
        <div>
          <TimeTypeSelect
            searchData={searchVehicleData}
            onTypeChange={setSearchVehicleData}
          />
          <TimeRadio
            searchData={searchVehicleData}
            onTypeChange={setSearchVehicleData}
          />
        </div>
        <ImageAndWords
          placeholder={`请输入${isRigested ? '姓名或者': '' }车牌号码搜索`}
          searchData={searchVehicleData}
          onTypeChange={setSearchVehicleData}
        />
      </div>
    );
  }
}

export default SearchVehicle;
