import React from "react";
import Card from "../cardVillage";
import { CVehicle } from "../../context";

@CVehicle
class Villages extends React.Component {
  render() {
    let { villages,checkVillage,activeVillage } = this.props;
    return (
      <div className="left-village-list">
        {villages.map(item => {
          const { id, villageName, address, pictureUrl } = item;
          return (
            <Card
              id={id}
              activeVillage={activeVillage}
              checkVillage={checkVillage}
              pictureUrl={pictureUrl}
              address={address}
              villageName={villageName}
              key={id}
            />
          );
        })}
      </div>
    );
  }
}
export default Villages;
