import React from "react";

const context = React.createContext(null);

export const Provider = context.Provider;
export const Consumer = context.Consumer;

export function CVehicle(Component) {
  class CvComponent extends React.Component {
    render() {
      const { forwardRef, ...props } = this.props;
      return (
        <Consumer>
          {context => {
            return <Component {...props} {...context} ref={forwardRef} />
          }}
        </Consumer>
      );
    }
  }
  return React.forwardRef((props, ref) => (
    <CvComponent {...props} forwardRef={ref} />
  ));
}
